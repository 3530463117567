@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    /* Avoid native behaviour like pull to reload */
    overscroll-behavior-y: none;
    overflow: hidden;
}

body {
    background-color: #0B0E18;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #FFFFFF;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: normal;
    src: url('/public/fonts/Roboto-Regular.ttf');
    font-display: swap;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('/public/fonts/MaterialIcons-Regular.ttf'); /* For IE6-8 */
    src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url('/public/fonts/MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
    font-family: 'Material Icons', sans-serif;
    font-weight: normal;
    font-style: normal;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

/* ---- mobileRedirectScreen */
.legalContent {
    color: #FFFFFF;
    font-weight: 500;
}

.legalContent h1 {
    color: #DBE2EC;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
}

.legalContent li {
    font-size: 16px;
    margin-left: 16px;
    line-height: 1.5;
    list-style-type: disc;
}

/* ---- multiplicationGameScreen */
.keyboard-css {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    width: 200px;
    margin: auto;
}

.row-css {
    display: contents;
}

.key-css {
    padding: 15px;
    font-size: 1.5rem;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f0f0f0;
    transition: background-color 0.2s ease;
}

.key-css:hover {
    background-color: #ddd;
}

.key-css:active {
    background-color: #bbb;
}
